import { NuxtAppOptions } from '@nuxt/types/app'
import { Etoken } from '@/utils/api'
import Cookie from 'cookie-universal'
import { portalUrl } from '@/utils/constants'
export default function checkLogin(app: NuxtAppOptions, data: any, nextUrl: string, to = false) {
  const router = app.router!
  if (data.need2fa) {
    router.push({
      name: app.localeRoute('/accounts/need2fa')?.name || 'index',
      params: data,
      query: { nextUrl },
    })
    return false
  }
  const cookies = Cookie()
  data?.token && cookies.set(Etoken, data?.token, { path: '/', maxAge: 5 * 24 * 60 * 60, sameSite: 'lax' })
  window.sessionStorage.removeItem('before3rdAccountUrl')
  if (!data.account.phone) {
    router.push({
      name: app.localeRoute('/accounts/add-user-info')?.name || 'index',
      params: data,
      query: { nextUrl },
    })
    return false
  }

  if (to) {
    window.location.href = nextUrl || portalUrl
  }

  return true
}
